import React from 'react';
import Layout from '../components/layout';
import ThanksCard from '../components/thanks-card/thanks-card';
import Badge from '../components/badge/badge';
import '../components/thanks-page/thanks-page.scss';
import useWindowSize from '../../libs/useWindowSize';

const pageName = 'thanks-page';

const ThanksPage = () => {
  const [width] = useWindowSize();
  const mainClass = width < 400 ? 'thanks-page__main' : null;

  return (
    <Layout pageName={pageName} mainClass={mainClass}>
      <ThanksCard
        className="thanks-page__thanks-card"
        title={(
          <>
            Спасибо
            <br />
            за покупку!
          </>
        )}
        subtitle="Ваш подарок ожидают получателей."
        text={(
          <>
            Мы ценим людей, которые дарят другим людям радость и в награду за это дарим вам бонусы!
            <div className="thanks-page__thanks-card-badge-wrap">
              <Badge className="thanks-page__thanks-card-badge">+300</Badge>
            </div>
          </>
        )}
        buttonText="Подарить еще"
        buttonLink="/"
      />
    </Layout>
  );
};

export default ThanksPage;
