import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import Button from '../buttons/button/button';
import './thanks-card.scss';

const ThanksCard = (props) => {
  const {
    className, title, subtitle, text, buttonText, buttonLink, buttonClass,
  } = props;

  const ROOT_CLASS = 'thanks-card';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const BUTTON_CLASS = 'thanks-card__button';
  const buttonClassName = mergeClasses(BUTTON_CLASS, buttonClass);

  const buttonProps = {};
  if (buttonLink) {
    buttonProps.type = 'link';
    buttonProps.href = buttonLink;
  }

  return (
    <div className={rootClass}>
      <div className="thanks-card__wrap">
        <h1 className="thanks-card__title page__title page__title_h1">{title}</h1>
        <div className="thanks-card__subtitle">{subtitle}</div>
        <div className="thanks-card__text">{text}</div>
        {buttonText && (
          <div className="thanks-card__button-wrap">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Button className={buttonClassName} outline theme="black" {...buttonProps}>{buttonText}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

ThanksCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonClass: PropTypes.string,
};

ThanksCard.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  text: '',
  buttonText: '',
  buttonLink: '',
  buttonClass: '',
};

export default ThanksCard;
