import React from 'react';
import PropTypes from 'prop-types';
import './badge.scss';
import { mergeClasses } from '../../../libs/helpers';

const Badge = ({ className, children }) => {
  const ROOT_CLASS = 'badge';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <span className={rootClass}>{children}</span>
  );
};

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};

Badge.defaultProps = {
  className: '',
};

export default Badge;
